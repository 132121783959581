.contact-div {
    background:rgb(239, 225, 204);
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 25%) 0px 8px 10px;
    margin: 20px;
    padding: 5px 5%;
}

#phone {
    text-decoration: none;
    font-weight: bold;
    border: solid thin black;
    padding: 10px;
    color: black;
    margin: 20px;
}

#phone:hover {
    background: black;
    color: white;
}