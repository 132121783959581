.image-grid {
    margin: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1%;
}

.image-overlay {
    position: relative;
    cursor: pointer;
}

.image-text {
    opacity: 0;
    position: absolute;
    bottom: 0;
    width: 94%;
    height: 35%;
    background-color: rgb(80, 35, 20);
    color: white;
    padding: 5px 3%;
    transition: 0.5s;
}

.image-overlay:hover img {
    opacity: 0.7;
    filter: brightness(40%);
}

.image-overlay:hover .image-text {
    opacity: 1;
}

.info-div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background:rgb(241, 225, 175);
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 25%) 0px 8px 10px;
    margin: 20px;
    padding: 20px;
}

@media screen and (max-width: 768px) {
    .image-grid {
        margin: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 1%;
    }
    
    .image-text {
        opacity: 1;
        font-size: 0.5rem;
        position: absolute;
        bottom: 0;
        width: 94%;
        height: 35%;
        background-color: rgb(80, 35, 20);
        color: white;
        padding: 5px 3%;
    }

    .image-overlay img {
        opacity: 0.7;
        filter: brightness(40%);
    }

    .info-div {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        background:rgb(241, 225, 175);
        border-radius: 5px;
        box-shadow: rgb(0 0 0 / 25%) 0px 8px 10px;
        margin: 20px;
        padding: 20px;
    }
}