header {
    --height: 60px;
    background: rgb(247, 236, 224);
    height: var(--height);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 9999;
}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--height);
    max-width: 1500px;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-item {
    color: rgb(80, 35, 20) !important;
    font-weight: bold;
    font-size: 1.2rem !important;
    border: none;
    background: none;
    cursor: pointer;
}

.nav-item:hover {
    color: rgb(214, 35, 0) !important;
    text-decoration: underline;
    text-decoration-thickness: 0.2rem;
    text-underline-offset: 5px;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 768px) {
    .navigation {
        z-index: -1;
    }

    .nav-menu {
        background: rgb(245, 235, 220);
        box-shadow: rgb(0 0 0 / 25%) 0px 8px 4px;
        position: absolute;
        top: -45vh;
        left: 0;
        padding-top: 20px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        row-gap: 30px;
        width: 100%;
        height: 40vh;
        transition: all 0.7s;
    }
    
    .nav-menu.active {
        top: var(--height);
        transition: all 0.7;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 30%);
        font-size: 1.8rem;
        cursor: pointer;
        color: rgb(80, 35, 20);
    }
}