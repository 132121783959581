footer {
    background-color: rgb(80, 35, 20);
}

#copy-right {
    display: block;
    background-color: rgb(43, 2, 0);
    color: rgb(245, 235, 220);
    padding: 10px 20px;
}

.underline-box {
    display: block;
    padding: 10px 20px;
    margin: 0px 10%;
    border-bottom: solid rgb(245, 235, 220) 1px;
}

.column-box {
    display: flex;
    flex-direction: row;
    column-gap: 20%;
    padding: 10px 20px;
    margin: 0px 10%;
}