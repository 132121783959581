body {
  margin: 0;
  font-family: 'Flame','Helvetica Neue','Helvetica','Arial','Verdana','sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(245, 235, 220);
}

.bold-text {
  font-family: "Flame", "Cooper Black", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: rgb(245, 235, 220);
}

.light-text {
  font-family: "Flame Sans","Helvetica Neue",Helvetica,Arial,Verdana,sans-serif;
  font-size: 1rem;
  color:rgb(245, 235, 220);
}

.two-element-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 768px) {
  .two-element-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
}
