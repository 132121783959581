.review-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.taster-heading {
    letter-spacing: 1px;
    margin: 10px 0px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.taster-heading h1 {
    font-size: 1.3rem;
    font-weight: 500px;
    background: black;
    color: white;
    padding: 10px 20px;
}

.taster-container {
    margin: 0px 20px 20px 40px;
    display: inline-grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, min-content);
    grid-gap: 20px;
}

.card {
    display: flex;
    flex-direction: column;
    background-color: rgb(217, 241, 247);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 10px 20px;
}

.from-right {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.checked {
    color: orange;
}

@media screen and (max-width: 540px) {
    .taster-container {
        margin: 0px 20px 20px 40px;
        display: inline-grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, min-content);
        grid-gap: 20px;
    }
}