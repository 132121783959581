.hour-div {
    background:rgb(239, 225, 204);
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 25%) 0px 8px 10px;
    margin: 20px;
}

.time-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 20px 10%;
}

.time-item {
    text-align: center;
}