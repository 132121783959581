.hero {
    display: flex;
    flex-direction: row;
    box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
    height: 450px;
    border-top: 5px solid rgb(214, 35, 0);
    border-bottom: 5px solid rgb(214, 35, 0);
}

.hero-content {
    position: absolute;
    top: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.hero-title {
    color: rgb(219, 201, 175) !important;
}

.hero-description {
    padding: 0;
    margin: 0;
    width: 50%;
    font-size: 1.1rem !important;
}

.hero-image-container {
    position: absolute;
    top: 15%;
    right: 10%;
    width: 25%;
    z-index: 2;
    padding: 20px;
}

.hero-image {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 768px) {
    .hero {
        border-top: 5px solid rgb(214, 35, 0);
        border-bottom: 5px solid rgb(214, 35, 0);
    }

    .hero-content {
        position: absolute;
        top: 10%;
        width: 60%;
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 2;
    }

    .hero-description {
        padding: 0;
        margin: 0;
        width: 80%;
        font-size: 0.5vw !important;
    }

    .hero-image-container {
        position: absolute;
        top: 25%;
        right: -5%;
        opacity: 90%;
        z-index: 1;
    }
}

@media (max-width: 1024px) {
    .hero-content {
        position: absolute;
        top: 10%;
        width: 60%;
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 2;
    }

    .hero-description {
        padding: 0;
        margin: 0;
        width: 80%;
        font-size: 1.1rem !important;
    }

    .hero-image-container {
        position: absolute;
        top: 15%;
        right: 0;
        z-index: 1;
    }
}