.side-border-grid {
    display: grid;
    grid-template-columns: 1fr 35%;
    margin: 10px 20px;
    border-right: 10px solid rgb(6, 174, 213);
    border-left: 10px solid rgb(6, 174, 213);
}

@media screen and (max-width: 768px) {
    .side-border-grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        margin: 10px 20px;
        border: none;
    }
}