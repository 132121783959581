.menu-heading {
  font-family: "Cursive";
  font-size: 4em;
  text-align: center;
  margin-top: 50px;
  letter-spacing: 2px;
  padding: 40px 0;
}

.menu-heading span {
  display: inline-block;
  border-bottom: 3px solid rgb(80, 35, 20);
  padding-bottom: 10px;
  margin: 0 20px;
}

@media (min-width: 768px) {
  .menu-heading::before,
  .menu-heading::after {
    content: "";
    display: inline-block;
    width: 35%;
    height: 3px;
    background-color: rgb(80, 35, 20);
    margin: 0 20px;
  }

  .menu-heading::before {
    margin-left: -5%;
  }

  .menu-heading::after {
    margin-right: -5%;
  }
}

.category-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.category-button {
  height: 50px;
  min-width: 150px;
  color: #1C2C59;
  border: 2px solid #1C2C59;
  background-color: transparent;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  text-overflow: ellipsis;
}

.category-button:hover,
.category-button.active {
  background-color: #FCBA4F;
}

.menu {
  margin: 5px 40px;
}

.menu-group-heading {
  font: bold;
  text-transform: uppercase;
  color: rgb(60, 136, 121);
  margin: 20px 40px;
  text-align: center;
  padding-bottom: 1em;
  border-bottom: 4px dotted rgb(0, 0, 0);
  border-top: 4px dotted rgb(0, 0, 0);
  padding-top: 1em;
}

.menu-group {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5em;
  padding: 1.5em 0;
}

.menu-item {
  display: flex;
  padding: 10px;
  border: 4px double #333;
  border-radius: 10px;
}

.menu-item-text {
  flex-grow: 1;
}

.menu-item h3 {
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.menu-item-desc {
  line-height: 1.6;
}

.set-menu-item {
  background-color: rgb(72, 209, 239);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
}

.set-menu-title {
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.set-menu-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.set-menu-list li {
  margin-bottom: 5px;
}

@media screen and (min-width: 992px) {
  .menu-group {
    grid-template-columns: repeat(3, 1fr);
  }
}

.spicy {
  color: rgb(233, 72, 40);
}

.vegetarian {
  color: rgb(25, 135, 55);
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gallery-item {
  position: relative;
  margin: 10px;
  flex: 1 0 300px;
}

.gallery-item img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.gallery-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gallery-item:hover:before {
  opacity: 1;
}

.gallery-item:hover img {
  opacity: 0.7;
}

.allergy-box {
  margin: 0;
  padding: 10px 40px;
  background-color: rgb(233, 72, 40);
  color: rgb(245, 235, 220);
  height: 10%;
  font-weight: bold;
}